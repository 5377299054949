<template>
    <div class="right-box">
        <!-- 基本信息 -->
        <div class="right-box__top">
            <div class="right-box-bar">
                <span>基本信息</span>
                <el-button plain size="small" style="width:68px;padding:7px 0" @click="toEidtInfo">编辑</el-button>
            </div>

            <div style="padding:40px" class="flex-row-start">
                <div style="flex-shrink:0;margin-right:80px">
                    <el-image :src="userInfos.headPortrait" class="head-image"
                        :preview-src-list="[userInfos.headPortrait]"></el-image>
                    <div style="margin:20px 0;width:100px" class="text-line1">
                        {{ userInfos.userName || "-" }}
                    </div>
                    <el-button plain size="small" @click="toRegister" :disabled="isSettleUser" class="settle-button">
                        {{ isSettleUser ? "已认证" : "去认证" }}
                    </el-button>
                </div>
                <div style="text-align:left">
                    <div>
                        <span class="color8 mr40">昵称</span>
                        <span>{{ userInfos.userName }}</span>
                    </div>
                    <div class="mt30">
                        <span class="color8 mr40">性别</span>
                        <span v-if="userInfos.sex === 1">男</span>
                        <span v-else-if="userInfos.sex === 2">女</span>
                        <span v-else>未设置</span>
                    </div>
                    <div class="mt30">
                        <span class="color8 mr40">生日</span>
                        <span>{{ displayBirthday }}</span>
                    </div>
                    <div class="mt30">
                        <span class="color8 mr40">地区</span>
                        <span>{{ displayLocation }}</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="right-box__bottom">
            <div class="right-box-bar">
                <span>账号信息</span>
            </div>
            <div style="padding:40px;text-align:left;margin-left:180px">
                <div>
                    <span class="color8 mr40">绑定手机</span>
                    <span class="mr40">{{ displayPhoneNumber }}</span>
                    <el-button type="text" @click="toShowDialogForm('phone')" class="btn-color"
                        style="padding:0;background:#fff;border:none">更换绑定</el-button>
                </div>
                <div class="mt30">
                    <span class="color8 mr40">绑定邮箱</span>
                    <span class="mr40">{{ userInfos.email || "未绑定" }}</span>
                    <el-button type="text" @click="toShowDialogForm('email')" class="btn-color"
                        style="padding:0;background:#fff;border:none">
                        {{ userInfos.email ? "去修改" : "去设置" }}
                    </el-button>
                </div>
                <div class="mt30">
                    <span class="color8 mr40">登录密码</span>
                    <span class="mr40" :style="{ color: !userInfos.password ? '#EE1E1E' : '#212121' }">
                        {{ !userInfos.password ? "未设置" : "已设置" }}
                    </span>
                    <el-button type="text" @click="toShowDialogForm('loginPassword')" class="btn-color"
                        style="padding:0;background:#fff;border:none">
                        {{ userInfos.password ? "去修改" : "去设置" }}</el-button>
                </div>
                <div class="mt30">
                    <span class="color8 mr40">支付密码</span>
                    <span class="mr40" :style="{ color: !userInfos.payPassword ? '#EE1E1E' : '#212121' }">
                        {{ !userInfos.payPassword ? "未设置" : "已设置" }}
                    </span>
                    <el-button type="text" @click="toShowDialogForm('payPassword')" class="btn-color"
                        style="padding:0;background:#fff;border:none">
                        {{ userInfos.payPassword ? "去修改" : "去设置" }}</el-button>
                </div>
            </div>
        </div>

        <dialog-form ref="dialogForm" :confirmBtnStatus="confirmBtnStatus" @onConfirm="dialogConfirm"
            :dialogTitle="dialogTitle" :submitLoading="submitLoading" @onCancel="dialogCancel">
            <div slot="form" class="dialog-class">
                <el-form :model="dialogFormData" label-position="right" :rules="rules" ref="form">
                    <el-form-item label="新的手机号码：" prop="phoneNumber" label-width="150px" v-if="dialogFormType === 'phone'">
                        <el-input v-model="dialogFormData.phoneNumber" style="width:256px" clearable></el-input>
                    </el-form-item>

                    <template v-if="dialogFormType === 'loginPassword' || dialogFormType === 'payPassword'">
                        <el-form-item label="原密码：" prop="currentPassword" label-width="150px" v-if="editPwd && !isResetPwd">
                            <el-input v-model="dialogFormData.currentPassword" style="width:256px" clearable type="password"
                                show-password :maxlength="passwordLength" placeholder="请输入原密码"></el-input>
                            <el-button type="text" style="color:#007EF2;font-size:16px"
                                @click="toForgetPwd">忘记密码?</el-button>
                        </el-form-item>
                        <el-form-item label="新密码：" prop="password" label-width="150px">
                            <el-input v-model="dialogFormData.password" style="width:256px" clearable type="password"
                                show-password :maxlength="passwordLength" placeholder="请输入新密码"></el-input>
                        </el-form-item>
                        <el-form-item label="确认密码：" prop="newPassword" label-width="150px">
                            <el-input v-model="dialogFormData.newPassword" style="width:256px" clearable type="password"
                                show-password :maxlength="passwordLength" placeholder="请输入确认密码"></el-input>
                        </el-form-item>
                    </template>

                    <el-form-item label="短信验证码：" label-width="150px" prop="code"
                        v-if="dialogFormType === 'phone' || isResetPwd">
                        <el-input v-model="dialogFormData.code" style="width:160px" clearable maxlength="6"></el-input>
                        <el-button type="text" @click="toSendCode" :disabled="!canSendCode"
                            style="font-size:16px;padding:0;margin-left:10px;" size="small">
                            {{ countDown === 60 ? "发送验证码" : countDown + 's重新发送' }}
                        </el-button>
                    </el-form-item>

                    <el-form-item label="邮箱地址：" prop="email" label-width="150px" v-if="dialogFormType === 'email'">
                        <el-input v-model="dialogFormData.email" style="width:256px" clearable
                            placeholder="请输入邮箱地址"></el-input>
                    </el-form-item>

                </el-form>
            </div>
        </dialog-form>

    </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import { isPhone } from "@/utils/validate"
import { updateUserPhone, updateUserEmail, updatePassword, resetPassword } from "@api/personalCenter"
import {
    NEWsendVerifyCode,
} from "@api/common";
import dialogForm from "../components/dialog-form/dialog-form.vue"
export default {
    components: {
        dialogForm,
    },
    data() {
        var validatePhone = function (rule, value, callback) {
            if (!isPhone(value)) {
                callback(new Error('请输入正确的手机号码'));
            }
            callback();
        }
        return {
            dialogFormType: "",
            dialogFormData: {
                phoneNumber: "",
                code: "",
                password: "",
                newPassword: "",
                email: "",
                currentPassword: "",
            },
            isResetPwd: false,
            editPwd: false,
            submitLoading: false,
            rules: {
                phoneNumber: [{ required: true, message: '请输入联系电话', trigger: 'blur' },
                { validator: validatePhone, trigger: 'blur' }],
                email: [
                    { required: true, message: '请输入邮箱地址', trigger: 'blur' },
                    { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
                ],
                code: [{ required: true, message: '请输入验证码', trigger: 'blur' }],

                currentPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: this.validatePassword, trigger: "blur" }
                ],
                password: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: this.validatePassword, trigger: "blur" },
                    { validator: this.valiSameNew, trigger: ["blur", "change"] }
                ],
                newPassword: [
                    { required: true, message: '请输入密码', trigger: 'blur' },
                    { validator: this.validatePassword, trigger: "blur" },
                    { validator: this.valiSamePwd, trigger: ["blur", "change"] }
                ],
            },
            isSendCode: false,//是否发送过验证码
            canSendCode: true,//是否可以发送验证码
            timer: null,
            countDown: 60,
        }
    },
    computed: {
        ...mapState("account", ["userInfos"]),

        isSettleUser() {
            if (this.userInfos?.realName) return true
            return false
        },

        confirmBtnStatus() {
            let formData = this.dialogFormData
            // 表单按钮状态
            if (this.dialogFormType === 'email') {
                if (!formData.email) return false
                return true
            }
            if (this.dialogFormType === "phone") {
                if (!formData.phoneNumber || !formData.code) return false
                return true
            }

            if (this.dialogFormType === "loginPassword" || this.dialogFormType === "payPassword") {
                if (this.isResetPwd) {
                    if (!formData.password || !formData.code || !formData.newPassword) return false
                    return true
                } else if (this.editPwd) {
                    if (!formData.password || !formData.currentPassword || !formData.newPassword) return false
                    return true
                } else {
                    if (!formData.password || !formData.newPassword) return false
                    return true
                }
            }

            return false
        },
        passwordLength() {
            return this.dialogFormType === "loginPassword" ? 16 : 6
        },

        displayLocation() {
            if (!this.userInfos) return "-"
            let { provinceName, cityName, regionName } = this.userInfos
            if (!provinceName && !cityName && !regionName) return "-"

            return `${provinceName}${(cityName === provinceName || cityName === "直辖市") ? '' : cityName}${regionName}`
        },
        displayBirthday() {
            if (!this.userInfos) return "-"
            let birthday = this.userInfos.birthday
            if (birthday) {
                return birthday.split(" ")?.[0] || "-"
            } else {
                return "-"
            }
        },
        displayPhoneNumber() {
            if (!this.userInfos) return "-"
            let loginPhoneNumber = this.userInfos.loginPhoneNumber
            return "*******" + loginPhoneNumber.substring(7, 11)
        },


        dialogTitle() {
            if (this.dialogFormType === "phone") {
                return this.userInfos.loginPhoneNumber ? "更换绑定手机号码" : "验证手机号码"
            }

            if (this.dialogFormType === "email") {
                return "绑定邮箱地址"
            }

            if (this.dialogFormType === "loginPassword") {
                return this.userInfos.password ? '修改登录密码' : '设置登录密码'
            }

            if (this.dialogFormType === "payPassword") {
                return this.userInfos.password ? '修改支付密码' : '设置支付密码'
            }

            return ""
        }
    },
    async created() {
        if (!this.userInfos) {
            this.getNewUserInfo()
        }
    },
    methods: {
        toUpdateUserInfo() {
            this.$store.dispatch("account/getNewUserInfo")
            if (window?.opener) {
                window.opener.postMessage("updateUserInfo", "*")
            }
        },

        ...mapActions('account', ['getNewUserInfo']),

        toEidtInfo() {
            this.$router.push("/personalCenter/editInfo")
        },

        toRegister() {
            if (this.userInfos.realName) return
            this.$router.push({
                path: "/personalCenter/personEnter",
            })
        },

        toShowDialogForm(type) {
            this.dialogFormType = type
            if (type === "email") {
                this.dialogFormData.email = this.userInfos.email || ""
            }

            if (type === "loginPassword" || type === "payPassword") {
                this.dialogFormData.password = ''
                this.dialogFormData.currentPassword = ""
                this.dialogFormData.newPassword = ""
            }

            if (type === "loginPassword" && this.userInfos.password) {
                this.editPwd = true
            }
            if (type === "payPassword" && this.userInfos.payPassword) {
                this.editPwd = true
            }
            this.$refs.dialogForm.visible = true
        },


        // 发送验证码
        async toSendCode() {
            try {
                if (!this.canSendCode) return
                if (this.timer) {
                    this.countDown = 60
                    clearInterval(this.timer)
                }
                let phone = this.dialogFormData.phoneNumber
                if (!phone) {
                    this.$message.warning("请输入联系电话")
                    return
                }
                if (!isPhone(phone)) {
                    this.$message.warning("请输入正确的联系电话")
                    return
                }
                this.timer = setInterval(() => {
                    if (!this.isSendCode) this.isSendCode = true
                    this.countDown--
                    if (this.countDown <= 0) {
                        this.countDown = 60
                        this.canSendCode = true
                        clearInterval(this.timer)
                    } else {
                        this.canSendCode = false
                    }
                }, 1000);

                await NEWsendVerifyCode({
                    phone,
                    smsEnum: "PHONE_COMMON",
                })
                this.$message.success("发送短信成功");
            } catch (err) {
                console.log("33333333333", err)
            }
        },

        dialogCancel() {
            if (this.timer) {
                clearInterval(this.timer)
                this.timer = null
                this.countDown = 60
                this.canSendCode = true
            }
            this.editPwd = false
            this.$refs.form.resetFields()
            this.$refs.dialogForm.visible = false
            this.isResetPwd = false
            this.canSendCode = true
            this.isSendCode = false
        },

        dialogConfirm() {
            try {
                this.$refs.form.validate(async vali => {
                    if (!vali) return
                    if (this.dialogFormType === "phone") {
                        this.updateUserPhone()
                        return
                    }
                    if (this.dialogFormType === "email") {
                        this.updateUserEmail()
                        return
                    }
                    if (this.dialogFormType === "loginPassword" || this.dialogFormType === 'payPassword') {
                        this.updateUserPassword()
                        return
                    }
                })
            } catch (err) {

            }
        },

        // 更换绑定手机号
        async updateUserPhone() {
            try {
                if (!this.isSendCode) {
                    this.$message.warning("请先获取验证码")
                    return
                }
                this.submitLoading = true
                let params = {
                    code: this.dialogFormData.code,
                    phone: this.dialogFormData.phoneNumber
                }
                await updateUserPhone(params)
                // 刷新store
                this.toUpdateUserInfo()

                this.$message.success("绑定成功")
                this.dialogCancel()

            } catch (err) {
                // this.$message.error(err?.msg || "绑定失败")
            } finally {
                this.submitLoading = false
            }
        },

        async updateUserEmail() {
            try {
                this.submitLoading = true
                await updateUserEmail({
                    email: this.dialogFormData.email
                })
                // 刷新store
                this.toUpdateUserInfo()
                this.$message.success("设置成功")
                this.dialogCancel()
            } catch (err) {
                console.log("3333333333", err)
                // this.$message.error(err?.msg || "设置失败")
            } finally {
                this.submitLoading = false
            }
        },

        // 忘记密码
        toForgetPwd() {
            this.isResetPwd = true
            this.dialogFormData.phoneNumber = this.userInfos.loginPhoneNumber
            this.toSendCode()
        },

        async updateUserPassword() {
            try {
                this.submitLoading = true
                let params = {
                    type: this.dialogFormType === "loginPassword" ? 1 : 2, //登录或支付密码
                    newOrUp: true,
                    newPassword: this.dialogFormData.newPassword,
                }
                if (this.editPwd) {
                    params.password = this.dialogFormData.currentPassword
                }

                if (this.isResetPwd) {
                    if (!this.isSendCode) {
                        this.$message.warning("请先获取验证码")
                        return
                    }
                    await resetPassword({
                        phone: this.dialogFormData.phoneNumber,
                        type: this.dialogFormType === "loginPassword" ? 1 : 2,
                        code: this.dialogFormData.code,
                        newPassword: this.dialogFormData.newPassword
                    })
                } else {
                    await updatePassword(params)
                }
                this.toUpdateUserInfo()
                this.$message.success("设置成功")
                this.dialogCancel()
            } catch (err) {
                // this.$message.error(err?.msg || "设置失败")
            } finally {
                this.submitLoading = false
            }
        },


        validatePassword(rule, value, callback) {
            if (this.dialogFormType === "loginPassword") {
                if (!/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[^\da-zA-Z\s]).{8,16}$/.test(value)) {
                    callback(new Error("密码仅支持8-16位的英文字母、数字、字符组合"))
                }
                callback();
            }

            if (this.dialogFormType === "payPassword") {
                if (!/^[0-9]{6}$/.test(value)) {
                    callback(new Error("密码仅支持6位数字"))
                }
                callback();
            }
        },

        valiSameNew(rule, value, callback) {
            if (this.dialogFormData.currentPassword === value) {
                callback(new Error("新密码不能与原密码相同"))
            }
            callback();
        },
        valiSamePwd(rule, value, callback) {
            if (this.dialogFormData.password !== value) {
                callback(new Error("两次密码不一致"))
            }
            callback();
        },
    }
}

</script>

<style scoped lang="less">
.btn-color {
    color: #1890FF;
    background: #1890FF;
    border-color: #1890FF;
    font-size: 16px;
}

.flex-row-start {
    display: flex;
    align-items: flex-start;
}

.mt30 {
    margin-top: 30px;
}

.color8 {
    color: #888;
}

.color-blue {
    color: #1890FF;
}

.mr40 {
    margin-right: 40px;
}


.dialog-class {
    padding-bottom: 20px;
    text-align: left;
}

.right-box {
    width: 1260px;
    color: #212121;

    .right-box__top {
        background: #fff;

        .head-image {
            width: 70px;
            height: 70px;
            border-radius: 70px;
            margin-bottom: 10px;
        }
    }

    .right-box__bottom {
        margin-top: 20px;
        background: #fff;
    }

    .right-box-bar {
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #EDF4FF;

        span {
            font-weight: 600;
        }
    }
}

.settle-button {
    border-color: #2180EF;
    padding: 6px 30px;
    color: #2180EF;
}

.settle-button:hover {
    border-color: #2180EF;
    color: #2180EF;
}

.text-line1 {
    word-break: break-all;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    white-space: pre-wrap;
}
</style>