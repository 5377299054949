<template>
    <div class="dialog">
        <el-dialog :visible.sync="visible" width="480px" lock-scroll :close-on-click-modal="false" :before-close="onCancel">
            <div slot="title" class="dialog-header">
                {{ dialogTitle || "验证手机号码" }}
            </div>
            <slot name="form"></slot>
            <div>
                <el-button class="btn" style="color:#007EF2;background:#fff;" @click="onCancel">取消</el-button>
                <el-button class="btn" type="primary" :disabled="!confirmBtnStatus" :class="{ 'default-btn': !confirmBtnStatus }" @click="onConfirm"
                    v-loading="submitLoading">确认</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialogTitle: [String],
        autoClose: [Boolean],
        confirmBtnStatus: [Boolean],
        submitLoading: [Boolean],
    },
    data() {
        return {
            visible: false,
        }
    },
    methods: {
        onCancel() {
            this.visible = false
            this.$emit("onCancel")
        },
        onConfirm() {
            if (this.autoClose) this.visible = false
            this.$emit("onConfirm")
        },
    }

}
</script>

<style lang="less" scoped>
.dialog {
    .dialog-header {
        background: #fafafa;
        text-align: left;
        margin: -20px;
        padding: 20px;
        font-size: 20px;
    }

    .btn {
        padding: 12px 0;
        width: 100px;
        border-color: #007EF2;
        background: #007EF2;
    }

    .default-btn {
        background: #D8D8D8;
        color: #fff;
        border-color: #D8D8D8;
    }
}
</style>