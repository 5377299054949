<template>
    <div class="content">
        <left-bar></left-bar>
        <!-- 首页基础信息 -->
        <base-info></base-info>
    </div>
</template>

<script>

import leftBar from '@/views/personalCenter/components/left-bar/left-bar'
import baseInfo from "./base-info.vue"
export default {
    components: {
        leftBar,
        baseInfo,
    },
    data() {
        return {
            pathKey: "personalCenter",
        }
    },
    created() {
        document.title = "邦建通-个人中心";
    },

}
</script>

<style scoped>
.content {
    background: #F8F9FB;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding: 20px;
    font-family: Source Han Sans CN-Regular;
    color: #212121;
}
</style>